<template>
  <div id="map">
    <img id="mapFallback" alt="Map Bg" src="@/assets/images/here_map_fallback.jpg">  
  <!--In the following div the HERE Map will render-->
    <div id="mapContainer" style="height:680px;width:100%" ref="hereMap"></div>
  </div>
</template>

<script>
import store from '@/store/store.js';

export default {
  name: "HereMap",
  props: {
    center: Object,
    markerObjects: Array
  },
  data() {
    return {
      store,
      platform: null,
      apikey: "iWJzor-mGR77xrZuRlUWEIgtRVlrlv6ZW6V77Dd4Tqc"
    };
  },
  async mounted() {
    // Initialize the platform object:
    const platform = new window.H.service.Platform({
      apikey: this.apikey
    });
    this.platform = platform;
    this.initializeHereMap();
  },
  methods: {
    initializeHereMap() { // rendering map

        const mapContainer = this.$refs.hereMap;
        const H = window.H;
        // Obtain the default map types from the platform object
        var maptypes = this.platform.createDefaultLayers();

        // Instantiate (and display) a map object:
        var map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: 6,
        center: this.center
        });

        
        addEventListener("resize", () => map.getViewPort().resize());

        var behaviour = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        behaviour.disable(H.mapevents.Behavior.WHEELZOOM);
        behaviour.disable(H.mapevents.Behavior.ZOOM);

        // add UI
        //var ui = H.ui.UI.createDefault(map, maptypes);
        //ui.getControl('zoom').setDisabled(false);


        // End rendering the initial map
        for(var i = 0; i < this.markerObjects.length; i++){
            // Create image marker object
            var outerElement = document.createElement('div');
            var innerElement = document.createElement('img');
            innerElement.id = "mapIcon_"+i;
            innerElement.classList.add("map-icon")
            innerElement.src = require('@/assets/images/icons/map_icon.png');
            outerElement.appendChild(innerElement);
            var domIcon = new H.map.DomIcon(outerElement);

            // Marker for Chicago Bears home
            var imageMarker = new H.map.DomMarker({lat: this.markerObjects[i].lat, lng: this.markerObjects[i].long}, {
                icon: domIcon
            });
            imageMarker.clickId = this.markerObjects[i].id;
            imageMarker.setData(i);

            imageMarker.addEventListener('pointerenter', this.overIcon);
            imageMarker.addEventListener('pointerleave', this.outIcon);
            imageMarker.addEventListener('tap', this.tapIcon);
            map.addObject(imageMarker);
        }   
    },
    tapIcon(evt) {
      this.showCollectorsDetail(evt.target.clickId);
    },
    overIcon(evt) {
      if(document.getElementById("mapIcon_"+evt.target.getData()) != null){
        document.getElementById("mapIcon_"+evt.target.getData()).style.animation = "bounce 0.4s alternate";
      }
      if(document.getElementById("collectorHeadline_"+evt.target.getData()) != null){
        document.getElementById("collectorHeadline_"+evt.target.getData()).style.color = "#D50000";
      }
    },
    outIcon(evt) {
      if(document.getElementById("mapIcon_"+evt.target.getData()) != null){
        document.getElementById("mapIcon_"+evt.target.getData()).style.animation = null;
      }
      if(document.getElementById("collectorHeadline_"+evt.target.getData()) != null){
        document.getElementById("collectorHeadline_"+evt.target.getData()).style.color = "black";
      } 
    },
    showCollectorsDetail(collectorId){
      this.store.selectedCollectorIndex = collectorId;
      this.$router.push({ path: 'collectorDetail', query: { collectorId: collectorId } })
    },
  }
};
</script>

<style>
#map {
  width: 100%;
  min-width: 0;
  text-align: center;
  background-color: #ccc;
}
canvas{
  opacity: 0;
}
#mapFallback{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.map-icon{
    cursor: pointer;

}
@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-webkit-keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>