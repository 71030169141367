<template>
<div class="projects_list_wrapper">
  <div class="projects_list">
    <h1>Ortsgruppen</h1>

    <div class="project__map collectors__map">
      <HereMap :center="center" zoom="false" :markerObjects="markerObjects" />
    </div>  

    <div class="collectors__projects">
        <div class="project" v-for="(group, index) in store.cityGroups" v-bind:id="'collector_'+index" :key="group.id" v-on:mouseover="hoverGroup(index)" v-on:mouseout="hoverOutGroup(index)" v-on:click="showCollectorsDetail(group.id)">
          <!-- header -->
          <div class="project__header_content">
            <h4 v-bind:id="'collectorHeadline_'+index" :key="group.id">{{group.organisationName}}</h4>
          </div>
        </div> 
    </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/store.js';
import HereMap from "@/components/HereMap.vue"

export default {
  name: 'Collectors',
  metaInfo: {
    title: 'Second Bandshirt - Collectors',
    htmlAttrs: {
      lang: 'de',
      amp: true
    }
  },
  components: {
    HereMap
  },
  data() {
    return {
      store,
      //german center location
      center:{ 
        lat: 50.9807, 
        lng: 10.31522
      },
    };
  },
  computed: {
    markerObjects: function () {
      var markerObjectList = [];
      for(var i = 0; i < this.store.cityGroups.length; i++){
          var markerObject = {
            lat: this.store.cityGroups[i].organisationLat,
            long: this.store.cityGroups[i].organisationLong,
            id: this.store.cityGroups[i].id
          }
          markerObjectList.push(markerObject);
      }
      
      return markerObjectList;
    },
  },
  methods: {
    showCollectorsDetail(collectorId){
      this.store.selectedCollectorIndex = collectorId;
      this.$router.push({ path: 'collectorDetail', query: { collectorId: collectorId } })
    },
    hoverGroup(index){
      document.getElementById("mapIcon_"+index).style.animation = "bounce 0.4s alternate";
      document.getElementById("collectorHeadline_"+index).style.color = "#D50000";
    },
    hoverOutGroup(index){
      document.getElementById("mapIcon_"+index).style.animation = null;
      document.getElementById("collectorHeadline_"+index).style.color = "black";
    },
     textEllipsis(str, maxLength, { side = "end", ellipsis = "..." } = {}) {
       if(str != null){
        if (str.length > maxLength) {
          switch (side) {
            case "start":
              return ellipsis + str.slice(-(maxLength - ellipsis.length));
            case "end":
            default:
              return str.slice(0, maxLength - ellipsis.length) + ellipsis;
          }
        }
       }
      
      return str;
    }
  }
}
</script>

<style lang="scss">
.projects_list_wrapper{
  overflow: hidden;
  margin-bottom: 50px;
  min-height: 950px;
  h1{
    padding-bottom: 30px;
  }
}
  .projects_list{
    position: relative;
    padding-left: 0;
    margin-bottom: -7px;
  }
  .collectors{
    &__map{
      position: relative;
      right: 0;
      top: 0;
      width: 650px;
      @media only screen and (min-width: 712px){ 
        position: absolute;
        right: 16px;
        top: 233px;
        width: 650px;
      }
    }
    &__projects{
      width: 40%;
      overflow: auto;
      position: absolute;
      left: 23px;
      top: 240px;
      background-color: rgba(0,0,0,0.8);
      padding-top: 16px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 8px;
      @media only screen and (min-width: 450px){ 
        width: 35%;
      }
      @media only screen and (min-width: 1280px){ 
        position: relative;
        width: 50%;
        overflow: hidden;
        left: 0;
        top: 0;
        background-color: transparent;
        padding: 0;
      }
      .project{
        width: auto;
        margin: 0;
        margin-bottom: 7px;
        padding: 4px;
        padding-bottom: 1px;
        display: block;
        background-color: white;
        width: auto;
        @media only screen and (min-width: 512px){ 
          padding: 8px;
          padding-bottom: 2px;
        }
        @media only screen and (min-width: 1280px){ 
          width: 91%;
        }
        h4{
          font-size: 15px;
           @media only screen and (min-width: 512px){ 
            font-size: 20px;
          }
        }
        &:hover {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        }
      }
    }
  }
  #map[data-v-0b27c34c] {
    width: 100%;
    min-width: 0;
    text-align: left;
    margin: 0;
    background-color: #ccc;
}
</style>
